import { Component, OnInit } from '@angular/core';
import json from './data.json';
import objectFitImages from 'object-fit-images';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public loading = true;
    public fadeOutLoader = false;
    public images = [
        './assets/app_img.png',
        './assets/arrow_next.png',
        './assets/arrow_prev.png',
        './assets/back_arrow.png',
        './assets/dropdown-arrow.png',
        './assets/en_slider_title_1_mobile.png',
        './assets/en_slider_title_1.png',
        './assets/en_slider_title_2_mobile.png',
        './assets/en_slider_title_2.png',
        './assets/en_slider_title_3_mobile.png',
        './assets/en_slider_title_3.png',
        './assets/en_slider_title_4_mobile.png',
        './assets/en_slider_title_4.png',
        './assets/en_slider_title_5_mobile.png',
        './assets/en_slider_title_5.png',
        './assets/en_slider_title_6_mobile.png',
        './assets/en_slider_title_6.png',
        './assets/es_slider_title_1_mobile.png',
        './assets/es_slider_title_1.png',
        './assets/es_slider_title_2_mobile.png',
        './assets/es_slider_title_2.png',
        './assets/es_slider_title_3_mobile.png',
        './assets/es_slider_title_3.png',
        './assets/es_slider_title_4_mobile.png',
        './assets/es_slider_title_4.png',
        './assets/es_slider_title_5_mobile.png',
        './assets/es_slider_title_5.png',
        './assets/es_slider_title_6_mobile.png',
        './assets/es_slider_title_6.png',
        './assets/faq_img.png',
        './assets/logo.png',
        './assets/rewards_img.png',
        './assets/slider_img_1_mobile.png',
        './assets/slider_img_1.png',
        './assets/slider_img_2_mobile.png',
        './assets/slider_img_2.png',
        './assets/slider_img_3_mobile.png',
        './assets/slider_img_3.png',
        './assets/slider_img_4_mobile.png',
        './assets/slider_img_4.png',
        './assets/slider_img_5_mobile.png',
        './assets/slider_img_5.png',
        './assets/slider_img_6_mobile.png',
        './assets/slider_img_6.png',
        './assets/specialty_img.png',
    ];
    public activeLanguage = 'en';
    public data = json;
    public activeData: any;
    public sliderConfig = {
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnFocus: false,
        pauseOnHover: false,
        infinite: true,
        prevArrow: '<div class="prev"></div>',
        nextArrow: '<div class="next"></div>'
    };
    public activePage = '';
    public hideCallus = true;
    public mobile = false;

    constructor() { }

    ngOnInit(): void {
        if (/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) {
            this.mobile = true;
            window.addEventListener('orientationchange', this.checkHeight.bind(this));
        }

        if (!(matchMedia('(hover: none)').matches)) {
            document.body.classList.add('can-hover');
        }


        this.activeData = this.data.en;

        this.checkUrlParam();
        this.checkHeight();
        objectFitImages();
        this.preload();

        window.addEventListener('popstate', () => {
            this.checkUrlParam();
        });
    }

    public checkUrlParam(): void {
        var hashes = window.location.hash.replace('#', '').split('&');

        if (!hashes.length || hashes[0] === '') {
            this.handleUrl('#en');
        }

        if (hashes[0] === 'es') {
            this.changeLanguage(null, 'es');
        } else if (hashes[0] === 'en') {
            this.changeLanguage(null, 'en');
        }

        if (hashes[1]) {
            switch (hashes[1]) {
                case 'faq':
                case 'rewards':
                case 'app':
                case 'callus':
                case 'specialty':
                    this.activePage = hashes[1];
                break;
            }
        } else {
            this.activePage = '';
        }
    }

    public checkHeight(): void {
        if (this.mobile) {
            const check = setInterval(this.setHeight.bind(this), 10);
            setTimeout(() => {
                clearInterval(check);
            }, 750);
        }
    }

    public setHeight(): void {
        let vh = window.innerHeight * 0.01;
        document.querySelector('.main-container')['style'].setProperty('--vh', `${vh}px`);
    }

    public preload(): void {
        let count = 0;

        for (const image of this.images) {
            const img = new Image();
            img.src = image;
            img.onload = () => {
                count++;

                if (count === this.images.length) {
                    setTimeout(() => {
                        this.fadeOutLoader = true;

                        setTimeout(() => {
                            this.loading = false;
                            setTimeout(() => {
                                this.hideCallus = false;
                            }, 350);
                        }, 350);
                    }, 500);
                }
            };
        }
    }

    public changeLanguage(event, lang): void {
        if (event) {
            event.preventDefault();
        }

        if (lang !== this.activeLanguage) {
            this.loading = true;
            this.fadeOutLoader = false;
            this.activeLanguage = lang;
            this.activeData = this.data[lang];

            let url = `#${this.activeLanguage}`;
            url += (this.activePage ? `&${this.activePage}` : '');
            this.handleUrl(url);

            setTimeout(() => {
                this.fadeOutLoader = true;

                setTimeout(() => {
                    this.loading = false;
                }, 500);
            }, 750);
        }
    }

    public linkClick(event, target): void {
        event.preventDefault();

        this.handleUrl(`#${this.activeLanguage}&${target}`);
        this.activePage = target;
    }

    public onClosePageOutput(event): void {
        this.handleUrl(`#${this.activeLanguage}`);
        this.activePage = '';
    }

    public handleUrl(url): void {
        if (history.pushState) {
            history.pushState(null, null, url);
        }
        else {
            location.hash = url;
        }
    }

    public getSlideId(index, slide) {
        return this.activeLanguage + '-' + index;
    }
}
